// Footer.js
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { useLocation } from "react-router-dom";
library.add(fas, fab);

const Footer = () => {
  const location = useLocation();

  // Condition to determine which logo to use based on the location
  // const background = location.pathname === '/blog' ? 'bg-white' : 'bg-black';
  const background =
    location.pathname === "/blog" || location.pathname === "/blog2"
      ? "bg-white"
      : "bg-black";

  // const textColor1 = location.pathname === '/blog' ? 'text-black-900' : 'text-white';
  const textColor1 =
    location.pathname === "/blog" || location.pathname === "/blog2"
      ? "text-black-900"
      : "text-white";

  return (
    <footer>
      <div
        className={`w-full bg-black-900 p-5 md:p-10 font-poppins ${background}`}
        style={{ boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.56)" }}
      >
        <div className="w-full mx-auto grid grid-cols-1 md:grid-cols-3 gap-4 text-left text-gray-100">
          <div className="w-full md:col-span-1 tracking-wider">
            <h3 className={`text-lg font-semibold ${textColor1}`}>
              BECOME CLOUD SAVVY TODAY!
            </h3>
            <hr className="border rounded w-32 md:w-46 my-2 md:my-3" />
            <a href="mailto:Info@Cloudevolve.In">
              <span className={`flex items-center text-sm ${textColor1}`}>
                partnerships@cloudevolve.com{" "}
                <FontAwesomeIcon
                  className="h-6 w-6 p-1 mx-2 md:mx-5 border rounded-full border-green-500 text-green-500"
                  icon="envelope"
                />
              </span>
            </a>
          </div>
          <div className="w-full md:col-span-1 tracking-wider">
            {/* <h3 className="text-lg font-semibold">HYDERABAD</h3>
            <hr className='border rounded w-24 md:w-16 my-2 md:my-3'/>
            <p className='whitespace-pre-line text-sm'>Plot No #7,<br />
                Srilatha Reddy Hibiscus,<br />
                Madhapur, Hyderabad,<br />
                Telangana, 500081.,<br />
                Contact: 040-46001634</p> */}
          </div>
          <div className="flex flex-wrap">
            <div
              className={`w-full md:w-1/2 px-2 tracking-wider ${textColor1}`}
            >
              <h3 className={`text-lg font-semibold ${textColor1}`}>INDIA</h3>
              <hr className="border rounded w-24 md:w-16 my-2 md:my-3" />
              <p className="whitespace-pre-line text-sm">
                2-23-B/14,
                <br />
                BSR RESIDENCY,
                <br />
                Kukatpally,
                <br /> Hyderabad,
                <br />
                Telangana-500072
              </p>
            </div>
            <div
              className={`w-full md:w-1/2 px-2 tracking-wider ${textColor1}`}
            >
              <h3 className={`text-lg font-semibold ${textColor1}`}>USA</h3>
              <hr className="border rounded w-24 md:w-16 my-2 md:my-3" />
              <p className="whitespace-pre-line text-sm">
                16192,
                <br />
                Coastal Highway,
                <br />
                Lewes,
                <br />
                Delaware, 19958,
                <br />
                County of Sussex
              </p>
            </div>
          </div>
        </div>
        <div className={`w-full mx-auto text-gray-100 ${textColor1}`}>
          <hr className="border rounded w-full my-3" />
        </div>
        <div className="w-full mx-auto my-2 grid md:grid-cols-2 gap-4 text-left text-gray-100">
          <div
            className={`hidden md:flex items-center text-sm tracking-wider gap-5 ${textColor1}`}
          >
            <a href="/home">ABOUT</a>
            <a href="/home">RESOURCES</a>
            <a href="/home">PLATFORM</a>
            <a href="/home">PRICING</a>
          </div>
          <div className="flex justify-center md:justify-end items-center space-x-4">
            <a href="/home">
              <FontAwesomeIcon
                className="h-6 w-6 p-1 border rounded-full bg-gray-100 text-black-900"
                icon={["fab", "instagram"]}
              />
            </a>
            <a href="/home">
              <FontAwesomeIcon
                className="h-6 w-6 p-1 border rounded-full bg-gray-100 text-black-900"
                icon={["fab", "facebook-f"]}
              />
            </a>
            <a href="/home">
              <FontAwesomeIcon
                className="h-6 w-6 p-1 border rounded-full bg-gray-100 text-black-900"
                icon={["fab", "whatsapp"]}
              />
            </a>
          </div>
        </div>
        <div className="w-full mx-auto grid grid-cols-1 gap-4 text-center md:text-left text-gray-100">
          <p className={`text-sm tracking-wider ${textColor1}`}>
            @CLOUDEVOLVE. ALL RIGHTS ARE RESERVED
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
